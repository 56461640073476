/* AVERTA STD */

/* averta - bold */
@font-face {
  font-family: 'Averta Std';
  font-style: normal;
  src: local('Averta Std'),
    /* IE6-IE8 */ url('../../assets/fonts/averta/averta-bold-webfont.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/averta/averta-bold-webfont.woff') format('woff');
}

/* OPEN-SANS */

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/open-sans/open-sans-v17-latin-300.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300italic.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300italic.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300italic.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300italic.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-300italic.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-regular.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-regular.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-regular.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-regular.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-regular.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-italic.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-italic.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-italic.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-italic.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-italic.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/open-sans/open-sans-v17-latin-600.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600italic.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600italic.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600italic.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600italic.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-600italic.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/open-sans/open-sans-v17-latin-700.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/open-sans/open-sans-v17-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700italic.eot?#iefix')
  format('embedded-opentype'),
    /* IE6-IE8 */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700italic.woff2')
  format('woff2'),
    /* Super Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700italic.woff')
  format('woff'),
    /* Modern Browsers */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700italic.ttf')
  format('truetype'),
    /* Safari, Android, iOS */
  url('../../assets/fonts/open-sans/open-sans-v17-latin-700italic.svg#OpenSans')
  format('svg'); /* Legacy iOS */
}