@use 'theme/fonts/fonts';

.MuiFormHelperText-root {
  color: #e85c73 !important;
  margin-left: 0 !important;
}

a {
  text-decoration:none !important;
}

body > #root > div {
  height: 100vh;
}